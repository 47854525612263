import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        addOutletToSchedule(context, scheduleOutlet) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await api.axios.post(`${api.url}/scheduleoutlets`, scheduleOutlet);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
    },
}
